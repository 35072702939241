<template>
  <div>
    <FullScreenDialog
      v-model="dialog"
      without-padding
      title="Movimenti del magazzino"
      :route-father="routeFather"
    >
<!--       <template v-slot:header-actions>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
        </v-menu>
      </template> -->

      <template v-slot:toolbar-extension> </template>
      <template v-slot:content>
        <div class="d-flex pa-5" style="width: 100%">
          <AdvancedFilter
            style="width:99%"
            v-model="filtersValue"
            :filters="filterTypes"
            :advance-filters="advanceFilters"
            :external-filter="externalFilter"
            @apply-filters="applyFilters"
          >
            <template v-slot:chip-custom-item.description="{filter}">
              Descrizione: {{ filter.value }}
            </template>

            <template v-slot:chip-custom-createdAtTime="{ filter }">
              Ora Creazione: {{ filter.value }}
            </template>

            <template v-slot:custom-movement_items.type="{filter}">
              <v-row>
                <v-col class="pa-4">
                  <v-autocomplete
                    v-model="filter.value"
                    :items="[
                      { value: 'unload', text: 'Scarico'},
                      { value: 'upload', text: 'Carico'}
                    ]"
                    dense
                    hide-details="auto"
                    label="Carico/Scarico"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </template>

            <template v-slot:custom-itemId="{filter}">
              <v-row class="pa-2 py-4">
                <v-col>
                  <ItemsAutocomplete
                    v-model="filter.value"
                    :filled="false"
                    :multiple="false"
                    :return-object="false"
                    dense
                  ></ItemsAutocomplete>
                </v-col>
              </v-row>
            </template>

            <template v-slot:custom-createdAtTime="{ filter }">
              <TimePicker
                v-model="filter.value"
              ></TimePicker>
            </template>
          </AdvancedFilter>
        </div>
        <div
          :style="{
            'height': $vuetify.breakpoint.smAndDown ? '81%' : '86%', 
            'overflow-y': 'hidden'
          }" 
          ref="dataTableWrapper"
        >
          <MovementDataTable
            :show-select="false"
            :headers="headers"
            :items="filteredMovements"
            :loading="loading"
            item-key="id"
            loading-text="Caricamento movimento"
            :translator="translator"
            fixed-header
            :show-actions="true"
            :height="dataTableHeight"
            :page.sync="page"
            :row-per-page.sync="rowPerPage"
            :total-pages.sync="totalPages"
            @dblclick:row="detailItemDoubleClick"
          >
            <template v-slot:custom-qty="{ item }">
              <v-chip
                class="aling-center justify-center"
                v-if="item['type'] == 'upload'"
                color="success"
              >
                {{ Number(item["qty"]).toLocaleString('it-IT') }}</v-chip
              >
              <v-chip class="aling-center justify-center" v-else color="error">
                {{ item["qty"] ? Number(item["qty"]).toLocaleString('it-IT') : 0 }}</v-chip
              >
            </template>

            <template v-slot:custom-createdAtTime="{ item }">
              <DateTimeFormattor
                :with-time="true"
                :with-date="false"
                v-model="item['createdAt']"
                not-specified-text="Non specificato"
              ></DateTimeFormattor>
            </template>

            <!-- <template v-slot:custom-qtyInStock="{ item }">
              <v-chip
                class="d-flex flex-wrap aling-center justify-center"
                v-if="item.item['qtyInStock'] > 0"
                color="success"
              >
                {{ item.item["qtyInStock"] }}</v-chip
              >
              <v-chip
                class="d-flex flex-wrap aling-center justify-center"
                v-else
                color="error"
              >
                {{
                  item.item["qtyInStock"] ? item.item["qtyInStock"] : 0
                }}</v-chip
              >
            </template> -->

            <template v-slot:custom-type="{ item }">
              <v-icon
                class="d-flex flex-wrap aling-center justify-center"
                v-if="item['type'] === 'upload'"
                color="success"
              >
                mdi-package-up</v-icon
              >
              <v-icon
                class="d-flex flex-wrap aling-center justify-center"
                v-else-if="item['type'] === 'unload'"
                color="error"
              >
                mdi-dolly</v-icon
              >
            </template>

            <template v-slot:actions="{ item }">
              <div style="min-width: 100px">
                <v-btn icon class="ml-1" @click="detailItem(item)">
                  <v-icon>mdi-clipboard-list</v-icon>
                </v-btn>
              </div>
            </template>
          </MovementDataTable>
        </div>
      </template>
    </FullScreenDialog>
  </div>
</template>
<script>
import MovementDataTable from "@/components/common/TypeDataTable";
import movementService from "@/services/warehouse/movements.service.js";
import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
import { SearchBar, SimpleList } from "likablehair-ui-components";
import _movementListFilter from "./filters/movementItemList";
import DateTimeFormattor from "@/components/common/DateTimeFormattor";
import AdvancedFilter from "@/components/common/AdvancedFilter.vue";
import ItemsAutocomplete from "@/components/common/ItemsAutocomplete.vue";
import TimePicker from '@/components/common/TimePicker.vue'

export default {
  name: "MovementItemList",
  components: {
    DateTimeFormattor,
    MovementDataTable,
    FullScreenDialog,
    SearchBar,
    SimpleList,
    AdvancedFilter,
    ItemsAutocomplete,
    TimePicker
  },
  data: function () {
    return {
      dialog: true,
      loading: false,
      movementItems: [],
      routeFather: this.pathToGoBack,
      openDialogMovementUpdate: false,
      movementListFilter: [],
      page: 1,
      rowPerPage: 100,
      totalPages: 100,
      dataTableHeightValue: 400,
      resizeListener: undefined,
      headers: [],
      filterTypes: [
        { type: 'custom', operator: 'equal', field: 'itemId', name: 'Prodotto', label: 'Prodotto', color: "", value: undefined, icon: 'mdi-spray-bottle' },
        { type: 'custom', operator: 'equal', field: 'movement_items.type', name: 'Carico/Scarico', label: '', color: "", value: undefined, icon: 'mdi-dolly' },
        { type: 'date', operator: 'between', field: 'createdAt', name: 'Data', label: '', color: "", value: [undefined, undefined] },
        { type: 'custom', operator: 'custom', field: 'createdAtTime', name: 'Ora Creazione', label: 'Ora Creazione', color: "", 
          value: ('0' + (new Date().getHours())).slice(-2) + ':' + ('0' + (new Date().getMinutes())).slice(-2), icon: 'mdi-clock-time-four-outline'},
        { type: 'string', operator: 'like', field: 'movement_items.code', name: 'Barcode', label: 'Barcode', color: "", value: undefined, icon: 'mdi-barcode' },
        { type: 'number', operator: 'equal', field: 'movement_items.qty', name: 'Quantità', label: 'Quantità', color: "", value: undefined, icon: 'mdi-numeric'},
      ],
      advanceFilters: [
        { type: 'string', operator: 'like', field: 'movement.code', name: 'Codice Interno Movimento', label: 'Codice Interno Movimento', color: "", value: undefined },
        { type: 'string', operator: 'like', field: 'item.internalCode', name: 'Codice Interno', label: 'Codice Interno', color: "", value: undefined }
      ],
      externalFilter: { type: 'custom', operator: 'like', field: 'item.description', name: 'Nome', label: 'Nome', color: "", value: undefined },
      filtersValue: [
      ]
    };
  },
  props: {
    pathToGoBack: {
      type: String,
      default: "/warehouse",
    },
    filters: {
      type: Array,
      default: function() {
        return [
        ]
      }
    }
  },
  mounted: function () {
    this.movementListFilter = _movementListFilter;

    this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 60;
    this.resizeListener = function () {
      this.dataTableHeightValue = this.$refs.dataTableWrapper.offsetHeight - 60;
    };
    this.resizeListener = this.resizeListener.bind(this);
    window.addEventListener("resize", this.resizeListener);
    this.fetchHeaders();
    this.filtersValue = this.filters
    this.fetchMovementItems();
  },
  beforeDestroy: function () {
    window.removeEventListener("resize", this.resizeListener);
  },
  methods: {
    reloadMovements() {
      this.openDialogMovementUpdate = false;

      this.fetchMovements();
    },
    fetchMovementItems() {
      this.loading = true;

      let filters = [...this.filtersValue]
      movementService
        .getMovementItems(this.page, this.rowPerPage, filters)
        .then((results) => {
          if (this.rowPerPage != results.rowPerPage) {
            this.rowPerPage = results.rowPerPage;
          }

          if (this.page != results.page) {
            this.page = results.page;
          }

          this.totalPages = results.totalPages;
          if (this.totalPages < this.page) {
            this.page = this.totalPages;
          }
          this.movementItems = results.rows;
          this.loading = false;
        });
    },
    fetchHeaders() {
      movementService._fields().then((headers) => {
        this.headers = headers;
      });
    },
    handleItemClick(item) {
      this.goTo(item.routeName);
    },
    goTo(routeName) {
      this.$router.push({ name: routeName }).catch(() => {});
    },
    detailItem(movement) {
      this.$router.push({
        name: "ItemHome",
        params: {
          id: movement.itemId,
          filters: this.filtersValue
        },
        query: {
          pathToGoBack: this.$router.resolve({ name: "MovementItemList" }).href,
          pathName: "MovementItemList",
        },
      });
    },
    detailItemDoubleClick(row, {item: movement}) {
      this.$router.push({
        name: "ItemHome",
        params: {
          id: movement.itemId,
          filters: this.filtersValue
        },
        query: {
          pathToGoBack: this.$router.resolve({ name: "MovementItemList" }).href,
          pathName: "MovementItemList",
        },
      });
    },
    translator(field, value) {
      if (field == "supplier" && value) {
        return value.businessName;
      }
    },
    applyFilters(filters) {
      this.fetchMovementItems();
    },
  },
  computed: {
    filteredMovements() {
      return this.movementItems;
    },
    dataTableHeight() {
      return this.dataTableHeightValue + "px";
    },
  },
  watch: {
    page() {
      this.fetchMovementItems();
    },
    rowPerPage() {
      this.fetchMovementItems();
    },
  },
};
</script>

<style>
</style>